<template>
  <div class="page page-box page-padding">
    <!-- 页头 -->
    <header class="page-header">
      <el-form :inline="true" :model="listQuery" class="demo-form-inline">
        <el-form-item label="教练姓名：">
          <template>
            <el-select class="input-width" v-model="listQuery.Name" filterable clearable remote reserve-keyword placeholder="请输入关键词" :remote-method="teacherMethod" :loading="optionLoading">
              <el-option v-for="item in teacherOptions" :key="item.Id" :label="item.Name" :value="item.Name">
              </el-option>
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="手机号：">
          <el-input v-model="listQuery.Phone" placeholder="请输入手机号" class="input-width" />
        </el-form-item>
        <el-form-item label="班级名称：">
          <template>
            <el-select class="input-width" v-model="listQuery.ClassName" filterable clearable remote reserve-keyword placeholder="请输入关键词" :remote-method="classMethod" :loading="optionLoading">
              <el-option v-for="item in classOptions" :key="item.Id" :label="item.Name" :value="item.Name">
              </el-option>
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select class="input-box" v-model="listQuery.Status" clearable placeholder="请选择">
            <el-option v-for="item in CoachStatusList" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button v-preventReClick @click="handleSearch" class="discoloration"> 查询 </el-button>
          <el-button class="plain-button" v-preventReClick @click="resetSearch"> 重置 </el-button>
        </el-form-item>
        <el-form-item class="scatter">
          <el-button @click="addOrEidtClubTeacher()" icon="el-icon-plus">
            添加教练
            <!-- <div class="img-center"> <img src="@/assets/add.png" alt="添加" class="add-img"> <span></span></div> -->
          </el-button>
          <el-button @click="getInviteVisible()" class="plain-button"> 二维码邀请教练 </el-button>
        </el-form-item>
      </el-form>
    </header>
    <!-- 主体 -->
    <main class="page-main-main" ref="main">
      <div class="examine-btn">
        <div>
        </div>
        <div>
          <el-button @click="approvedSelection()" class="plain-button"> 审核通过 </el-button>
          <el-button @click="rejectionSelection()" class="plain-button"> 审核拒绝 </el-button>
        </div>
      </div>
      <!-- 列表渲染 -->
      <el-table v-loading="loading" :data="tableList" ref="multipleTable" @selection-change="handleSelectionChange" border fit stripe>
        <el-table-column type="selection" :selectable="
          (row) => {
            return row.Status === 1
          }
        " width="55" align="center" />
        <el-table-column align="center" label="照片">
          <template slot-scope="{ row }">
            <div class="img-box">
              <el-image v-if="row.Photo" class="image-box" :src="`${cosHttp}${row.Photo}`" :preview-src-list="[`${cosHttp}${row.Photo}`]">
              </el-image>
              <el-image v-else class="image-box" :src="`${defaultImg}`" :preview-src-list="[`${defaultImg}`]">
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="Name" align="center" label="教练姓名" :show-overflow-tooltip="true" />
        <el-table-column prop="Sex" align="center" label="性别" width="60px">
          <template slot-scope="{ row }">
            <span> {{ row.Sex | coachSex }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="Phone" align="center" label="手机号" min-width="120px" />
        <el-table-column prop="ClassName" align="center" label="负责班级" :show-overflow-tooltip="true" min-width="160">
          <template slot-scope="{ row }">
            {{ Array.isArray(row.AtClass) && row.AtClass.map(item=> item.Name).join('/') }}
          </template>
        </el-table-column>
        <el-table-column prop="StudentCount" align="center" label="学员数" width="80px" />
        <el-table-column label="状态" align="center" width="80px">
          <template slot-scope="{ row }" w>
            <span v-if="row.Status===1" class="statusBox"><span class="awaitStatus"></span>{{ row.Status | coachStatus }}</span>
            <span v-if="row.Status===2" class="statusBox"><span class="consentStatus"></span>{{ row.Status | coachStatus }}</span>
            <span v-if="row.Status===3" class="statusBox"><span class="refuseStatus"></span>{{ row.Status | coachStatus }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="140px">
          <template slot-scope="{ row }">
            <el-button type="text" v-if="row.Status === 1" @click="addOrEidtClubTeacher(row)"> 审核 </el-button>
            <el-button type="text" v-if="row.Status === 2" @click="readClubTeacher(row)"> 详情 </el-button>
            <el-button type="text" v-if="row.Status === 3" @click="readClubTeacher(row)"> 查看详情 </el-button>
            <el-button type="text" v-if="row.Status === 2" @click="addOrEidtClubTeacher(row)"> 编辑 </el-button>
            <el-button type="text" v-if="row.Status === 2" @click="deleteClubTeacher(row)"> 删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <footer>
        <pagination v-show="total > 0" :total="total" :page.sync="listQuery.PageNo" :limit.sync="listQuery.PageSize" @pagination="getClubTeacherList" />
      </footer>
    </main>
    <!-- 新建编辑教练 -->
    <el-dialog :title="clubTeacherTitle" :close-on-click-modal='false' :visible.sync="clubTeacherVisible" width="50%">
      <el-form :model="clubTeacherForm" :rules="rules" ref="clubTeacherForm" label-width="120px">
        <el-form-item label="照片:" prop="Photo">
          <!-- element 上传图片按钮 -->
          <template>
            <div class="cropper-wrapper">
              <!-- element 上传图片按钮 -->
              <template v-if="!isPreview">
                <el-upload action="#" :show-file-list="false" :on-change="handleChangeUpload">
                  <img v-if="clubTeacherForm.Photo" :src="`${cosHttp}${clubTeacherForm.Photo}`" class="avatar">
                  <i v-else class="el-icon-full-screen avatar-uploader-icon avatar-uploader">
                    <br>
                    <span>上传头像</span></i>
                </el-upload>
                <span class="imgFont">(支持JPG、JPEG、PNG格式图片，20M大小以内)</span>
              </template>
              <div class="pre-box" v-else>
                <el-upload class="upload-demo" action="#" :show-file-list="false" :on-change="handleChangeUpload">
                  <!-- <el-button type="primary" plain>更换图片</el-button> -->
                  <img v-if="previewImg" :src="previewImg" alt="裁剪图片" class="avatar" />
                </el-upload>
                <span class="imgFont">(支持JPG、JPEG、PNG格式图片，20M大小以内)</span>
              </div>
              <!-- vueCropper 剪裁图片实现-->
              <el-dialog title="图片剪裁" :visible.sync="dialogVisible" class="crop-dialog" append-to-body :close-on-click-modal='false'>
                <div class="cropper-content">
                  <div class="cropper" style="text-align: center">
                    <vueCropper ref="cropper" :img="option.img" :outputSize="option.size" :outputType="option.outputType" :info="true" :full="option.full" :canMove="option.canMove" :canMoveBox="option.canMoveBox" :original="option.original" :autoCrop="option.autoCrop" :fixed="option.fixed" :fixedNumber="option.fixedNumber" :centerBox="option.centerBox" :infoTrue="option.infoTrue" :fixedBox="option.fixedBox" :autoCropWidth="option.autoCropWidth" :autoCropHeight="option.autoCropHeight" @cropMoving="cropMoving" />
                  </div>
                </div>
                <div class="action-box">
                  <el-upload class="upload-demo" action="#" :show-file-list="false" :on-change="handleChangeUpload">
                    <el-button type="primary" plain>更换图片</el-button>
                  </el-upload>
                  <el-button type="primary" plain @click="clearImgHandle">清除图片</el-button>
                  <el-button type="primary" plain @click="rotateLeftHandle">左旋转</el-button>
                  <el-button type="primary" plain @click="rotateRightHandle">右旋转</el-button>
                  <el-button type="primary" plain @click="changeScaleHandle(1)">放大</el-button>
                  <el-button type="primary" plain @click="changeScaleHandle(-1)">缩小</el-button>
                  <el-button type="primary" plain @click="downloadHandle('blob')">下载</el-button>
                </div>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogVisible = false">取 消</el-button>
                  <el-button class="discoloration" @click="finish" :loading="loading">确 认</el-button>
                </div>
              </el-dialog>
            </div>
          </template>
        </el-form-item>
        <el-form-item label="教练姓名:" prop="Name">
          <el-input v-model="clubTeacherForm.Name" placeholder="请输入教练姓名" type="text" show-word-limit maxlength="10" />
        </el-form-item>
        <el-form-item label="性别:" prop="Sex" required>
          <el-radio-group v-model="clubTeacherForm.Sex" @change='changeSexStatus'>
            <el-radio :label="1">男</el-radio>
            <el-radio :label="0">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="手机号:" prop="Phone">
          <el-input v-if="clubTeacherForm.Admin===true" v-model="clubTeacherForm.Phone" placeholder="请输入手机号" disabled />
          <el-input v-else v-model="clubTeacherForm.Phone" placeholder="请输入手机号" />
        </el-form-item>
        <el-form-item label="负责班级:" prop="ClassId">
          <el-row v-for="(item,index) in selectClassList" :key='index'>
            <el-col :span="7">
              <el-select class="select-box" v-model="item.ClassId" @change="changeClass(index)" placeholder="请选择班级">
                <el-option v-for="item in ClassList" :key="item.Id" :label="item.Name" :value="item.Id">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="5" :offset="1" class="col-box">
              <el-select class="select-box" v-model="item.TeacherType" placeholder="教练类型">
                <el-option v-for="item in TeacherTypeList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="3" :offset="1" class="col-box">
              管理员:
            </el-col>
            <el-col :span="3" class="col-box">
              <el-select class="select-box" v-model="item.Manager" placeholder="是否管理员">
                <el-option v-for="item in ManagerList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="2" :offset="1"><i class="el-icon-delete" @click="deleteClass(index)"></i></el-col>
          </el-row>
          <el-button icon="el-icon-plus" @click="addClass" class="discoloration">新增班级</el-button>
        </el-form-item>
        <el-form-item label="教练简介:" prop="Remarks">
          <el-input v-model="clubTeacherForm.Remarks" :autosize="{ minRows: 2 }" type="textarea" placeholder="请输入教练简介" show-word-limit maxlength="300" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button v-preventReClick v-if="clubTeacherTitle === '审核教练'" @click="cancelClubTeacher()" class="plain-button">拒 绝</el-button>
        <el-button v-else @click="clubTeacherVisible = false" class="plain-button">取 消</el-button>
        <el-button v-preventReClick @click="updateClubTeacher()" class="discoloration">{{ clubTeacherTitle === '审核教练'  ? '同 意' : '确 定' }}</el-button>
      </span>
    </el-dialog>
    <!-- 查看详情 -->
    <el-dialog title="详情" :visible.sync="readVisible" width="40%" :close-on-click-modal='false'>
      <el-row class="margin-row">
        <el-col :span="12">
          <span>姓名：</span><span>{{ readTeacherForm.Name }}</span>
        </el-col>
        <el-col :span="4">
          <span>照片：</span>
        </el-col>
        <el-col :span="2">
          <span>
            <el-image v-if="readTeacherForm.Photo" :src="`${cosHttp}${readTeacherForm.Photo}`" :preview-src-list="[`${cosHttp}${readTeacherForm.Photo}`]">
            </el-image>
            <el-image v-else :src="`${defaultImg}`" :preview-src-list="[`${defaultImg}`]">
            </el-image>
          </span>
        </el-col>
        <el-col :span="5" :offset="1">
          <span v-if="readTeacherForm.Status===2" class="statusIn">{{ readTeacherForm.Status | coachStatus }}</span>
          <span v-if="readTeacherForm.Status===3" class="statusOut">{{ readTeacherForm.Status | coachStatus }}</span>
        </el-col>
      </el-row>
      <el-row class="margin-row">
        <el-col :span="12">
          <span>性别：</span><span>{{ readTeacherForm.Sex | coachSex }}</span>
        </el-col>
        <el-col :span="12">
          <span>手机号：</span><span>{{ readTeacherForm.Phone }}</span>
        </el-col>
      </el-row>
      <el-row class="margin-row">
        <el-col :span="12">
          <span>学员数：</span>
          <span>{{readTeacherForm.StudentCount}}</span>
        </el-col>
      </el-row>
      <el-row class="margin-row">
        <el-col :span="12">
          <span>所负责班级：</span>
        </el-col>
      </el-row>
      <el-row class="margin-row">
        <el-col>
          <span v-for="(item,index) in readTeacherForm.AtClass" :key="index">
            <div class="margin-intent">
              {{index+1}}.{{ item.Name }}
            </div>
          </span>
        </el-col>
      </el-row>
      <el-row class="margin-row">
        <el-col>
          <span>简介：</span>
        </el-col>
      </el-row>
      <el-row class="margin-row">
        <el-col class="margin-intent">
          <span>{{ readTeacherForm.Remarks }}</span>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 二维码邀请加入 -->
    <el-dialog title="二维码邀请教练" :visible.sync="inviteVisible" width="30%" :close-on-click-modal='false'>
      <div class="invite-coach">
        <el-image :src="`${userInfo.OrgQrCode}`" :preview-src-list="[`${userInfo.OrgQrCode}`]" class="QR-img">
        </el-image>
        <el-button type="primary" @click="getQrCode()" class="cope-link">二维码下载</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getClubTeacherList, updateClubTeacher, delClubTeacher, checkTeacher, getAllClubTeacher } from "@/service/organizational";
import { getAllClubClass, classNameFind, teacherNameFind } from "@/service/educational";
import pagination from '@/components/pagination/pagination.vue';  // 引入分页
import { deepClone } from '@/utils/index';  // 引入深拷贝
import upload from '@/mixins/upload';
import { CoachStatusList, TeacherTypeList, ManagerList } from '@/config/data';
import vueQr from "vue-qr"; // 二维码
// import Clipboard from "clipboard"; // 复制
import { VueCropper } from 'vue-cropper';
import { mapState } from "vuex";


const ClubTeacherForm = { // 新增编辑教练的初始化数据
  Name: '',
  Remarks: '',
  Status: 0,
  Sex: 1,
  Phone: '',
  Photo: '',
};
const ListQuery = { // 查询数据
  PageNo: 1,  // 页码
  PageSize: 50, // 分页条数
  Name: '', // 教练名称筛选
  Status: '', // 教练状态
  Phone: '',  // 手机号码
  ClassName: '',  // 班级名称
};
export default {
  name: "Coach",
  mixins: [upload],
  components: {
    pagination,
    vueQr,
    VueCropper
  },
  data() {
    const validateName = (rule, value, callback) => {
      if (!value || value.trim() === '') {
        return callback(new Error('请输入教练名称'));
      }
      callback();
    };

    const validatePhone = (rule, value, callback) => {
      if (!value || value.trim() === '') {
        callback(new Error('请输入手机号'));
      } else {
        let p = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
        if (p.test(value.trim())) {
          callback();
        }
        return callback(new Error('请输入正确格式手机号'))
      }
    };

    const validatePhoto = (rule, value, callback) => {
      if (this.clubTeacherForm.Photo === '') {
        return callback(new Error('请上传头像'));
      }
      callback();
    };
    return {
      defaultImg: require('@/assets/defaultLogo.png'),
      fileName: "defualt",
      ClassList: [],
      selectClassList: [], // 新增班级列表
      TeacherTypeList,
      ManagerList,
      CoachStatusList,  // 教练状态
      clubTeacherForm: deepClone(ClubTeacherForm),
      listQuery: deepClone(ListQuery),
      tableList: [],  // 表格数据
      total: 0, // 表格总条数
      loading: false, // 加载状态
      clubTeacherTitle: '新增教练', // 新建编辑title
      clubTeacherVisible: false,  // 新建编辑教练弹窗状态
      inviteVisible: false, // 二维码邀请加入弹窗状态
      rules: {
        Name: [{ required: true, validator: validateName, trigger: 'blur' }],
        Phone: [{ required: true, validator: validatePhone, trigger: 'blur' }],
        Photo: [{ required: true, validator: validatePhoto, trigger: 'blur' }],
      },
      multipleSelection: [],  // 多选数据
      readVisible: false, // 详情初始状态
      readTeacherForm: {},  // 详情数据
      cosHttp: process.env.VUE_APP_COSHTTP,
      optionLoading: false,
      classOptions: [],
      teacherOptions: [],
      classList: [],
      teacherList: [],
      testUrl: "https://api.qx.flutterbbs.cn/qr/joinClub?code=",
      isPreview: false,
      dialogVisible: false,
      previewImg: '', // 预览图片地址
      // 裁剪组件的基础配置option
      option: {
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: 'png', // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        canMoveBox: true, // 截图框能否拖动
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: false, // 是否输出原图比例的截图
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      // 防止重复提交
      loading: false
    }
  },
  created() {
    this.getClubTeacherList();
    // this.getAllClubTeacher();
    this.getAllClubClass();
    this.classNameFind();
    this.teacherNameFind();
  },
  computed: {
    ...mapState(['userInfo'])
  },
  mounted() {
  },
  methods: {

    // 复制链接
    async copyShareLink() {
      let clipboard = new Clipboard(".tag-copy");
      await clipboard.on("success", e => {
        this.$message({
          type: "success",
          message: "复制成功，请到微信打开!"
        });
        clipboard.destroy(); // 释放内存
      });
      clipboard.on("error", e => {
        this.$message({
          type: "warning",
          message: "该浏览器不支持自动复制!"
        }); // 不支持复制
        clipboard.destroy(); // 释放内存
      });
    },

    classMethod(query) {
      if (query && query.trim() !== '') {
        this.optionLoading = true;
        setTimeout(() => {
          this.optionLoading = false;
          this.classOptions = this.classList.filter(item => {
            return item.Name.toLowerCase()
              .indexOf(query.trim().toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.classOptions = this.classList;
      }
    },

    teacherMethod(query) {
      if (query && query.trim() !== '') {
        this.optionLoading = true;
        setTimeout(() => {
          this.optionLoading = false;
          this.teacherOptions = this.teacherList.filter(item => {
            return item.Name.toLowerCase()
              .indexOf(query.trim().toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.teacherOptions = this.teacherList;
      }
    },

    /**
     * 获取所有班级列表
     */
    async classNameFind() {
      let res = await classNameFind();
      this.classList = res.map((item, index) => {
        let obj = {};
        obj.Name = item;
        obj.Id = index + 1
        return obj;
      });
      this.classOptions = this.classList;
    },
    async getAllClubClass() {
      let res = await getAllClubClass();
      this.ClassList = res;
    },

    /**
     * 获取所有教练
     */
    async teacherNameFind() {
      let res = await teacherNameFind();
      this.teacherList = res.map((item, index) => {
        let obj = {};
        obj.Name = item;
        obj.Id = index + 1
        return obj;
      });
      this.teacherOptions = this.teacherList;
    },

    /**
      * 获取教练列表数据
      */
    async getClubTeacherList(data) {
      if (data) {
        this.listQuery.PageNo = data.page;
        this.listQuery.PageSize = data.limit;
      }
      this.loading = true;
      let res = await getClubTeacherList(this.listQuery);
      this.loading = false;
      this.tableList = res.DataList;
      this.total = res.Total;
    },

    /**
     * 页头-查询
     */
    handleSearch() {
      this.listQuery.PageNo = 1;
      this.listQuery.Name = this.listQuery.Name && this.listQuery.Name.trim();
      this.listQuery.Phone = this.listQuery.Phone && this.listQuery.Phone.trim();
      this.listQuery.ClassName = this.listQuery.ClassName && this.listQuery.ClassName.trim();
      this.listQuery.Status = this.listQuery.Status || undefined;
      this.classOptions = this.classList;
      this.teacherOptions = this.teacherList;
      this.getClubTeacherList();
    },

    /**
     * 页头-重置
     */
    resetSearch() {
      this.listQuery = deepClone(ListQuery);
      this.classOptions = this.classList;
      this.teacherOptions = this.teacherList;
      this.getClubTeacherList();
    },

    /**
     * 操作-查看
     */
    readClubTeacher(row) {
      this.readVisible = true;
      this.readTeacherForm = { ...row };
    },

    /**
      * 操作/主要-新增或者编辑
      */
    addOrEidtClubTeacher(row) {
      this.selectClassList = [];
      this.clubTeacherVisible = true;
      this.isPreview = false;
      this.$nextTick(() => {
        this.$refs.clubTeacherForm.clearValidate();

      })
      if (row) {
        this.clubTeacherForm = { ...row };
        console.log(this.clubTeacherForm);
        let arr = this.clubTeacherForm.AtClass;
        this.selectClassList = arr.map(item => {
          let obj = { ...item };
          obj.ClassId = item.Id;
          return obj;
        })
        this.clubTeacherTitle = '编辑教练';
        if (row.Status === 1) {
          this.clubTeacherTitle = '审核教练';
        }
      } else {
        this.clubTeacherForm = deepClone(ClubTeacherForm);
        this.clubTeacherTitle = '新增教练';
      }
    },

    /**
     * 确定新增或者编辑
     */
    updateClubTeacher() {
      if (Array.isArray(this.selectClassList) && this.selectClassList.filter(item => !item.ClassId).length > 0) {
        this.$message.error('请选择班级');
        return;
      }
      this.$refs.clubTeacherForm.validate(async (valid) => {
        if (!valid) return;

        if (this.clubTeacherTitle !== '新增教练') {
          await updateClubTeacher({
            Id: this.clubTeacherForm.Id,
            Phone: this.clubTeacherForm.Phone,
            Sex: this.clubTeacherForm.Sex,
            Photo: this.clubTeacherForm.Photo,
            Name: this.clubTeacherForm.Name,
            Remarks: this.clubTeacherForm.Remarks,
            Status: this.clubTeacherTitle === '审核教练' ? 2 : undefined,
            AtClass: [...this.selectClassList]
          });
          this.clubTeacherTitle === '编辑教练' ? this.$message.success('编辑成功') : this.$message.success('审核成功');
        } else {
          await updateClubTeacher({
            ...this.clubTeacherForm,
            Status: undefined,
            AtClass: [...this.selectClassList]
          });
          this.$message.success('新建成功');
        }
        this.clubTeacherVisible = false;
        this.getClubTeacherList();
      })

    },

    async cancelClubTeacher() {
      await updateClubTeacher({
        Id: this.clubTeacherForm.Id,
        Phone: this.clubTeacherForm.Phone,
        Sex: this.clubTeacherForm.Sex,
        Photo: this.clubTeacherForm.Photo,
        Name: this.clubTeacherForm.Name,
        Remarks: this.clubTeacherForm.Remarks,
        Status: this.clubTeacherTitle === '审核教练' ? 3 : undefined,
        AtClass: [...this.selectClassList]
      });
      this.$message.success('拒绝成功');
      this.clubTeacherVisible = false;
      this.getClubTeacherList();
    },

    /**
     * 操作-删除
     */
    async deleteClubTeacher(row) {
      try {
        let res = await this.$confirm('确定要删除吗？', '警告', {
          distinguishCancelAndClose: true,
          confirmButtonText: '删 除',
          cancelButtonText: '取 消',
          type: 'warning',
          center: true,
        });
        if (res !== 'confirm') return;
        await delClubTeacher({ Id: row.Id });
        this.getClubTeacherList();
        this.$message({
          message: '删除成功',
          type: 'success'
        });
      } catch (err) { };
    },

    // 正在上传中...
    uploading(progressData) {
      let index = this.fileList.length - 1;
      this.fileList[index].percentage = progressData.percent * 100;
      this.fileList[index].status = 'uploading';
    },

    // 上传按钮 限制图片大小和类型
    handleChangeUpload(file) {
      if (JSON.parse(JSON.stringify(file)).status === 'ready') {
        const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/jpg';
        const isLt20M = file.raw.size / 1024 / 1024 < 20;

        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG/PNG/JPEG 格式!');
          return false;
        }
        if (!isLt20M) {
          this.$message.error('上传头像图片大小不能超过 20MB!');
          return false;
        }
        this.fileName = file.name.split('.')[0];
        // 上传成功后将图片地址赋值给裁剪框显示图片
        this.$nextTick(async () => {
          this.loading = true;
          // base64方式
          // this.option.img = await fileByBase64(file.raw)
          this.option.img = URL.createObjectURL(file.raw)
          this.loading = false;
          this.dialogVisible = true;
        })
      }
    },
    // 放大/缩小
    changeScaleHandle(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    // 左旋转
    rotateLeftHandle() {
      this.$refs.cropper.rotateLeft();
    },
    // 右旋转
    rotateRightHandle() {
      this.$refs.cropper.rotateRight();
    },
    // 下载
    downloadHandle(type) {
      let aLink = document.createElement('a');
      aLink.download = 'author-img';
      if (type === 'blob') {
        this.$refs.cropper.getCropBlob((data) => {
          let downImg = window.URL.createObjectURL(data);
          aLink.href = window.URL.createObjectURL(data);
          aLink.click();
        })
      } else {
        this.$refs.cropper.getCropData((data) => {
          let downImg = c;
          aLink.href = data;
          aLink.click();
        })
      }
    },
    // 清理图片
    clearImgHandle() {
      this.option.img = '';
    },
    // 截图框移动回调函数
    cropMoving(data) {
      // 截图框的左上角 x，y和右下角坐标x，y
      // let cropAxis = [data.axis.x1, data.axis.y1, data.axis.x2, data.axis.y2]
    },
    async finish() {
      // 获取截图的 blob 数据
      this.$refs.cropper.getCropData((data) => {
        this.loading = true;
        this.dialogVisible = false;
        this.previewImg = data;
        this.isPreview = true;
        this.loading = false;

        let arr = data.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let suffix = mime.split('/')[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        let file = new File([u8arr], `${this.fileName}.${suffix}`, {
          type: mime
        });
        this.uploadTecent(file).then(url => {
          this.clubTeacherForm.Photo = url;
        });
      })
    },

    /**
      * 二维码邀请加入
      */
    getInviteVisible() {
      this.inviteVisible = true;
    },

    /**
      * 主体-table表格多选框操作
      */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    /**
      * 主体-button审核通过操作
      */
    async approvedSelection() {
      if (Array.isArray(this.multipleSelection) && this.multipleSelection.length === 0) {
        this.$message.error('请勾选至少一条数据');
        return;
      }
      try {
        let res = await this.$confirm('确定审核通过吗？', '警告', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确 定',
          cancelButtonText: '取 消',
          type: 'warning',
          center: true,
        });
        if (res !== 'confirm') return;
        await checkTeacher({ Status: 2, TeacherIds: this.multipleSelection.map(item => item.Id) });
        this.getClubTeacherList();
        this.$message({
          message: '批量审核成功',
          type: 'success'
        });
      } catch (err) { };
    },

    /**
      * 主体-button审核拒绝操作
      */
    async rejectionSelection() {
      if (Array.isArray(this.multipleSelection) && this.multipleSelection.length === 0) {
        this.$message.error('请勾选至少一条数据');
        return;
      }
      try {
        let res = await this.$confirm('确定审核拒绝吗？', '警告', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确 定',
          cancelButtonText: '取 消',
          type: 'warning',
          center: true,
        });
        if (res !== 'confirm') return;
        await checkTeacher({ Status: 3, TeacherIds: this.multipleSelection.map(item => item.Id) });
        this.getClubTeacherList();
        this.$message({
          message: '批量拒绝成功',
          type: 'success'
        });
      } catch (err) { };
    },

    changeSexStatus() {
      this.$refs.clubTeacherForm.validateField('Sex');
      this.$forceUpdate();
    },

    /**
     * 判断选择的是否一样
     */
    changeClass(flag) {
      let bool = false;
      this.selectClassList.forEach((item, index) => {
        if (flag !== index && this.selectClassList[flag].ClassId === item.ClassId) {
          this.selectClassList[flag].ClassId = '';
          bool = true;
        }
      });
      if (bool) {
        this.$message.error('当前班级已被选择，请重新选择');
      }
    },

    /**
     * 删除教练
     */
    deleteClass(index) {
      this.selectClassList.splice(index, 1);
    },

    /**
     * 添加教练
     */
    addClass() {
      let obj = {
        TeacherType: 1,
        ClassId: '',
        Manager: false
      };
      this.selectClassList.push(obj);
    },

    // 二维码下载
    getQrCode() {
      const iconUrl = this.userInfo.OrgQrCode;
      const htmlElement = document.createElement('a');
      const event = new MouseEvent('click');
      htmlElement.download = `${this.userInfo.OrgName}`;
      htmlElement.href = iconUrl;
      htmlElement.dispatchEvent(event);
    }
  },
}
</script>
<style lang='scss' scoped>
.input-box {
  width: 100px;
}
.invite-coach {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .cope-link {
    color: #8fcc80;
    margin-top: 16px;
  }
  p {
    margin: 10px;
  }
}

/deep/.el-table__body tr,
.el-table__body td {
  height: 54px !important;
}
.cropper-content {
  .cropper {
    width: auto;
    height: 350px;
  }
}
.action-box {
  display: flex;
  justify-content: space-between;
  button {
    margin-left: 0;
  }
  margin-top: 24px;
}
/deep/.el-dialog__footer {
  padding-right: 24px !important;
}
.examine-btn {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
}
.QR-img {
  width: 180px;
  height: 180px;
}
/deep/.input-width {
  width: 140px !important;
}
.scatter {
  .el-button {
    display: inline-block;
    vertical-align: top;
  }
}
.statusIn {
  border: 1px solid #00b446;
  color: #00b446;
}
.statusOut {
  border: 1px solid #fe3a30;
  color: #fe3a30;
}
</style>